<template>
	<div class="notice_popup prj_itv pass">
		<div class="close">
			<img class="ml-0" src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<tec-summary tagNm="div" class="member" :value="data" />
		<div class="tit">프로젝트 인터뷰 결과</div>
        <img src="/images/admin/pop_itv_fail.png" alt="불합격">
		<InputComp type="textarea" title="불합격 사유" v-model="memoCont" placeholder="불합격 사유를 입력해주세요!" rules="required" v-if="data.mberSeq" :showError="false"/>
		<div class="qs">위 기술인재의 인터뷰 상태를 ‘불합격’으로 변경하시겠습니까?</div>
		<div class="select-btn">
			<div class="btn" @click="$emit('close', false)">취소</div>
			<div class="btn" @click="save">예</div>
		</div>
	</div>
</template>
<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	components: { tecSummary },
	props: { param:Object },
	data() {
		return {
			data: {},
			memoCont: '',
		};
	},
	mounted(){
		var projectSeq = this.param.projectSeq;
		var tecMberSeq = this.param.tecMberSeq;
		var interviewReqSeq = this.param.interviewReqSeq;
		this.$.httpPost('/api/prj/adm/getPrjMberInfo', {projectSeq, tecMberSeq, interviewReqSeq})
			.then(res => {
				this.data = res.data;
				this.$emit('updated');
			}).catch(this.$.httpErrorCommon);
	},
	methods: {
		save(){
			var interviewReqSeq = this.data.interviewReqSeq;
			var tecMberSeq		= this.param.tecMberSeq;
			var memoCont 		= this.memoCont;
			this.$.httpPost('/api/prj/adm/saveInterviewReject', {interviewReqSeq, tecMberSeq, memoCont})
				.then(() => {
					alert('불합격 처리가 완료되었습니다.')
					this.$emit('close', true);
				}).catch(this.$.httpErrorCommon);
		}
	},
};
</script>
